<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardBody>
            <v-select
              :options="list_tahun"
              v-model="tahun_filter"
              placeholder="Pilih Tahun"
              @input="changeFilterTahun"
            ></v-select>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow class="mb-4">
      <CCol
        sm="6"
        lg="2"
        v-if="
          user_data && user_data.roles && user_data.roles.is_dashboard_tp_access
        "
      >
        <CWidgetDropdown
          color="primary"
          :header="countnotif_tp"
          text="Benih Tanaman Pangan"
          class="h-100"
        >
          <!-- <img src="img/dashboard_1.png" style="position: absolute;right: 0;opacity: 0.5;"> -->
          <template #footer>
            <button
              class="btn btn-primary"
              v-on:click="
                getListStatus('Tanaman Pangan', 'Benih Tanaman Pangan')
              "
            >
              <i class="fa fa-eye"></i> Detail
            </button>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol
        sm="6"
        lg="2"
        v-if="
          user_data &&
          user_data.roles &&
          user_data.roles.is_dashboard_bun_access
        "
      >
        <CWidgetDropdown
          color="info"
          :header="countnotif_bun"
          text="Benih Tanaman Perkebunan"
          class="h-100"
        >
          <!-- <img src="img/dashboard_2.png" style="position: absolute;right: 0;opacity: 0.5;"> -->
          <template #footer>
            <button
              class="btn btn-info"
              v-on:click="getListStatus('Perkebunan', 'Benih Perkebunan')"
            >
              <i class="fa fa-eye"></i> Detail
            </button>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol
        sm="6"
        lg="2"
        v-if="
          user_data &&
          user_data.roles &&
          user_data.roles.is_dashboard_horti_access
        "
      >
        <CWidgetDropdown
          color="warning"
          :header="countnotif_horti"
          text="Benih Tanaman Hortikultura"
          class="h-100"
        >
          <!-- <img src="img/dashboard_3.png" style="position: absolute;right: 0;opacity: 0.5;"> -->
          <template #footer>
            <button
              class="btn btn-warning"
              v-on:click="getListStatus('Hortikultura', 'Benih Hortikultura')"
            >
              <i class="fa fa-eye"></i> Detail
            </button>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol
        sm="6"
        lg="2"
        v-if="
          user_data &&
          user_data.roles &&
          user_data.roles.is_dashboard_sdg_access
        "
      >
        <CWidgetDropdown
          color="danger"
          :header="countnotif_sdg"
          text="Sumber Daya Genetik"
          class="h-100"
        >
          <!-- <img src="img/dashboard_4.png" style="position: absolute;right: 0;opacity: 0.5;"> -->
          <template #footer>
            <button
              class="btn btn-danger"
              v-on:click="getListStatus('Sumber Daya Genetik', 'SDG Tanaman')"
            >
              <i class="fa fa-eye"></i> Detail
            </button>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol
        sm="6"
        lg="2"
        v-if="
          user_data &&
          user_data.roles &&
          user_data.roles.is_dashboard_hpt_access
        "
      >
        <CWidgetDropdown
          color="success"
          :header="countnotif_hpt"
          text="Benih Tanaman Pakan Ternak"
          class="h-100"
        >
          <!-- <img src="img/dashboard_4.png" style="position: absolute;right: 0;opacity: 0.5;"> -->
          <template #footer>
            <button
              class="btn btn-success"
              v-on:click="
                getListStatus(
                  'Benih Tanaman Pakan Ternak',
                  'Benih Tanaman Pakan Ternak'
                )
              "
            >
              <i class="fa fa-eye"></i> Detail
            </button>
          </template>
        </CWidgetDropdown>
      </CCol>

      <CCol sm="12" class="mt-3" v-if="detailTanaman">
        <CRow class="mb-3">
          <CCol sm="6" class="mt-3" v-if="detailTanaman">
            <CCard>
              <CCardHeader style="color: white; background: #01ae6e">
                <b>Permohonan Pemasukan {{ detailTanamanTitle }}</b>
              </CCardHeader>
              <CCardBody>
                <table class="table table-sm table-bordered">
                  <thead style="color: white; background: #01ae6e">
                    <tr>
                      <th style="width: 15px">No</th>
                      <th>Status Permohonan</th>
                      <th>Estimasi waktu</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(val_pemasukan, key_pemasukan) in status_pemasukan"
                      :key="key_pemasukan"
                    >
                      <td>{{ key_pemasukan + 1 }}</td>
                      <template>
                        <td v-if="val_pemasukan.eselon_1 != '-'">
                          {{ val_pemasukan.st_nama }} -
                          {{ val_pemasukan.eselon_1.toUpperCase() }}
                        </td>
                        <td v-else>{{ val_pemasukan.st_nama }}</td>
                        <td>
                          <template v-if="val_pemasukan.estimasi"
                            >{{ val_pemasukan.estimasi }} Jam</template
                          >
                        </td>
                      </template>
                      <td>
                        <router-link
                          :to="{
                            name: 'ListPermohonan',
                            params: { pr_kategori: detailTanamanTitle },
                            query: {
                              tahun: tahun_filter,
                              m_nama: 'Pemasukan ' + detailTanamanTitledb,
                              status_permohonan_id: val_pemasukan.id,
                            },
                          }"
                        >
                          {{ val_pemasukan.jumlah }}
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="6" class="mt-3" v-if="detailTanaman">
            <CCard>
              <CCardHeader style="color: white; background: #01ae6e">
                <b
                  >Permohonan Pengeluaran {{ detailTanamanTitle }}
                  {{ detailTanamanTitledb }}</b
                >
              </CCardHeader>
              <CCardBody>
                <table class="table table-sm table-bordered">
                  <thead style="color: white; background: #01ae6e">
                    <tr>
                      <th style="width: 15px">No</th>
                      <th>Status Permohonan</th>
                      <th>Estimasi waktu</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        val_pengeluaran, key_pengeluaran
                      ) in status_pengeluaran"
                      :key="key_pengeluaran"
                    >
                      <td>{{ key_pengeluaran + 1 }}</td>
                      <template>
                        <td v-if="val_pengeluaran.eselon_1 != '-'">
                          {{ val_pengeluaran.st_nama }} -
                          {{ val_pengeluaran.eselon_1.toUpperCase() }}
                        </td>
                        <td v-else>{{ val_pengeluaran.st_nama }}</td>
                      </template>
                      <td>
                        <template v-if="val_pengeluaran.estimasi"
                          >{{ val_pengeluaran.estimasi }} Jam</template
                        >
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'ListPermohonan',
                            params: { pr_kategori: detailTanamanTitle },
                            query: {
                              tahun: tahun_filter,
                              m_nama: 'Pengeluaran ' + detailTanamanTitledb,
                              status_permohonan_id: val_pengeluaran.id,
                            },
                          }"
                        >
                          {{ val_pengeluaran.jumlah }}
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader> List Semua Permohonan </CCardHeader>
          <CCardBody>
            <div class="row">
              <div class="col-md-12">
                <div class="row row-condensed">
                  <div class="as-col col-md">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="as-col col-md">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="as-col col-md-2">
                    <div class="form-group">
                      <b for="">Perusahaan</b>
                      <v-select
                        :options="list_perusahaan"
                        label="p_nama"
                        v-model="perusahaan_selected"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md-2">
                    <div class="form-group">
                      <b for="">Tipe Permohonan</b>
                      <v-select
                        :options="filter_tipe"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="is_pemasukan"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <!--<div class="as-col col-md-2">
                    <div class="form-group">
                      <b for="">Module Permohonan</b>
                      <v-select
                        :options="filter_module"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="pr_kategori"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>-->
                  <div class="as-col col-md-2">
                    <div class="form-group">
                      <b for="">Status</b>
                      <v-select
                        :options="filter_status"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="status"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="as-col col-md">
                    <div class="form-group">
                      <b for="">Search</b>
                      <input
                        type="text"
                        class="form-control"
                        v-model="search_permohonan"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-5 d-flex justify-content-start"
                style="margin-bottom: 10px; gap: 10px"
              >
                <div
                  class="btn btn-sm btn-primary"
                  v-on:click="filterPermohonan"
                >
                  <i class="fa fa-filter"></i> Filter
                </div>

                <div class="btn btn-sm btn-success" v-on:click="getExcel">
                  <i class="fa fa-download"></i> Excel
                </div>
                <div
                  class="btn btn-sm btn-danger"
                  v-on:click="modalFilterPDF = true"
                >
                  <i class="fa fa-download"></i> {{ buttonLabel }}
                </div>
              </div>
            </div>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <table
                      class="table table-hover table-responsive table-bordered table-sm no-footer"
                    >
                      <thead class="bg-dark text-white">
                        <tr>
                          <th class="align-top" rowspan="2">No</th>
                          <th>Kode Permohonan</th>
                          <th>NIB Perusahaan</th>
                          <th>Nama Perusahaan</th>
                          <th>Kategori Permohonan</th>
                          <th>Nomor Surat</th>
                          <th>Tanggal Pengajuan</th>
                          <th>Estimasi Waktu</th>
                          <th>Status</th>
                          <!-- <th>Komoditas</th> -->
                          <th>Jml. Komoditas</th>
                          <th class="align-top" rowspan="2">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-if="loadingTable">
                            <td colspan="11">
                              <i class="fa fa-spin fa-refresh"></i> Loading...
                            </td>
                          </tr>
                          <tr
                            v-for="(
                              value_list_permohonan, key_list_permohonan
                            ) in list_permohonan"
                            :key="key_list_permohonan"
                            v-else
                          >
                            <td>
                              <template>
                                <div v-if="key_list_permohonan + 1 == 10">
                                  {{ curent_page }}0
                                </div>
                                <div v-else>
                                  <template>
                                    <span v-if="curent_page - 1 != 0">
                                      {{ curent_page - 1
                                      }}{{ key_list_permohonan + 1 }}
                                    </span>
                                    <span v-else>
                                      {{ key_list_permohonan + 1 }}
                                    </span>
                                  </template>
                                </div>
                              </template>
                            </td>
                            <td>{{ value_list_permohonan.kode }}</td>
                            <td>
                              <template>
                                <div
                                  v-if="
                                    value_list_permohonan.tipe_pemohon_id != 2
                                  "
                                >
                                  {{ value_list_permohonan.nib }}
                                </div>
                                <div v-else>Data Tidak Tersedia</div>
                              </template>
                            </td>
                            <td>{{ value_list_permohonan.perusahaan }}</td>
                            <td>{{ value_list_permohonan.m_nama }}</td>
                            <td>{{ value_list_permohonan.pr_nomor_surat }}</td>
                            <td>
                              {{ value_list_permohonan.pr_tgl_pengajuan }}
                            </td>
                            <td>
                              <template
                                v-if="value_list_permohonan.estimasi_hari"
                              >
                                {{ value_list_permohonan.estimasi_hari }} jam,
                                atau <br />
                                {{ value_list_permohonan.due_date }}
                                {{ value_list_permohonan.jam_selesai }}
                              </template>
                            </td>
                            <td>
                              <template>
                                <CBadge
                                  :color="
                                    getBadge(
                                      value_list_permohonan.status_permohonan
                                    )
                                  "
                                  v-if="
                                    value_list_permohonan.status_permohonan ==
                                    'Pengajuan Permohonan'
                                  "
                                >
                                  <div>Draft</div>
                                </CBadge>
                                <CBadge
                                  :color="
                                    getBadge(
                                      value_list_permohonan.status_permohonan
                                    )
                                  "
                                  v-else
                                >
                                  <div>
                                    {{
                                      value_list_permohonan.status_permohonan
                                    }}
                                  </div>
                                </CBadge>
                              </template>
                            </td>
                            <!-- <td>
                              <div
                                v-for="(tanaman, index) in parseTanamanNama(
                                  value_list_permohonan.tanaman_nama
                                )"
                                :key="index"
                              >
                                {{ index + 1 }}. {{ tanaman }}
                              </div>
                            </td> -->
                            <td>
                              {{ value_list_permohonan.total_jumlah }}
                            </td>
                            <td>
                              <template>
                                <template>
                                  <div
                                    v-if="value_list_permohonan.is_pemasukan"
                                  >
                                    <router-link
                                      :to="{
                                        path:
                                          'permohonan/detail-permohonan-pemasukan/' +
                                          value_list_permohonan.pr_kategori.replaceAll(
                                            ' ',
                                            '-'
                                          ) +
                                          '/' +
                                          value_list_permohonan.id,
                                      }"
                                      class="btn btn-block btn-info btn-sm"
                                      ><i class="fa fa-eye"></i>
                                      Detail</router-link
                                    >
                                  </div>
                                  <div v-else>
                                    <router-link
                                      :to="{
                                        path:
                                          'permohonan/detail-permohonan-pengeluaran/' +
                                          value_list_permohonan.pr_kategori.replaceAll(
                                            ' ',
                                            '-'
                                          ) +
                                          '/' +
                                          value_list_permohonan.id,
                                      }"
                                      class="btn btn-block btn-info btn-sm"
                                      ><i class="fa fa-eye"></i>
                                      Detail</router-link
                                    >
                                  </div>
                                </template>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <nav aria-label="pagination">
                      <ul class="pagination justify-content-start">
                        <!-- <li
                          v-for="(page, i) in paging"
                          :key="i"
                          v-bind:class="{ active: page.active }"
                          class="page-item"
                        >
                          <div
                            v-on:click="toPage(page.url)"
                            class="page-link c-page-link-number"
                          >
                            <div v-html="page.label"></div>
                          </div>
                        </li> -->
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
            <!-- form Filter PDF -->
            <template>
              <div>
                <CModal
                  title="Filter data"
                  color="btn-primary"
                  :show.sync="modalFilterPDF"
                  size="md"
                >
                  <CRow>
                    <CCol md="6">
                      <div class="form-group">
                        <b for="">Tgl Awal</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="start_pdf"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div class="form-group">
                        <b for="">Tgl Akhir</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="end_pdf"
                          :disabled-dates="disabledDates"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div class="form-group">
                        <b for="">Perusahaan</b>
                        <v-select
                          :options="list_perusahaan"
                          label="p_nama"
                          v-model="perusahaan_selected_pdf"
                          :value="$store.myValue"
                        ></v-select>
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div class="form-group">
                        <b for="">Tipe Permohonan</b>
                        <v-select
                          :options="filter_tipe"
                          :reduce="(label) => label.value"
                          label="label"
                          v-model="is_pemasukan_pdf"
                          :value="$store.myValue"
                        ></v-select>
                      </div>
                    </CCol>
                    <!-- <CCol md="6">
                      <div class="form-group">
                        <b for="">Module Permohonan</b>
                        <v-select
                          :options="filter_module"
                          :reduce="(label) => label.value"
                          label="label"
                          v-model="pr_kategori"
                          :value="$store.myValue"
                        ></v-select>
                      </div>
                    </CCol> -->
                    <CCol md="6">
                      <div class="form-group">
                        <b for="">Status</b>
                        <v-select
                          :options="filter_status"
                          :reduce="(label) => label.value"
                          label="label"
                          v-model="status_pdf"
                          :value="$store.myValue"
                        ></v-select>
                      </div>
                    </CCol>
                  </CRow>
                  <template #footer>
                    <CButton @click="modalFilterPDF = false" color="secondary"
                      >Tutup</CButton
                    >
                    <CButton
                      v-on:click="getPDFLink()"
                      color="primary"
                      :disabled="isDownloading"
                      >{{ buttonLabel }}</CButton
                    >
                  </template>
                </CModal>
              </div>
            </template>

            <!-- form Filter PDF selesai -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
var today = new Date();
var year = today.getFullYear();
import "core-js/stable";
import Vue from "vue";
// import WidgetsDropdown from "./widgets/WidgetsDropdown";
const null_array = [];
export default {
  name: "Dashboard",
  components: {
    // WidgetsDropdown,
    vuejsDatepicker,
  },
  data() {
    return {
      // Search Column
      kode: "",
      nib: "",
      perusahaan: "",
      pemohon: "",
      pr_kategori: "",
      m_nama: "",
      pr_nomor_surat: "",
      pr_tanggal: "",
      status_permohonan: "",
      // Lain - Lain
      list_permohonan: null_array,
      paging: null_array,
      curent_page: "",
      loadingTable: false,
      session_data: JSON.parse(this.session),
      is_pemasukan: "",
      start: "",
      end: "",
      status: "",
      excel_url: "",
      list_perusahaan: "",
      perusahaan_selected: "",
      search_permohonan: "",
      tahun_filter: "",
      list_tahun: ["Semua"],
      filter_status: [
        {
          value: "baru",
          label: "Baru",
        },
        {
          value: "menunggu perbaikan",
          label: "Menunggu Perbaikan",
        },
        {
          value: "dibatalkan",
          label: "Dibatalkan",
        },
        {
          value: "proses",
          label: "Proses",
        },
        {
          value: "selesai",
          label: "Selesai",
        },
      ],
      pr_kategori: [],
      filter_module: [
        {
          value: "Hortikultura",
          label: "Hortikultura",
        },
        {
          value: "Sumber Daya Genetik",
          label: "Sumber Daya Genetik",
        },
        {
          value: "Tanaman Pangan",
          label: "Tanaman Pangan",
        },
        {
          value: "Perkebunan",
          label: "Perkebunan",
        },
        {
          value: "Benih Tanaman Pakan Ternak",
          label: "Benih Tanaman Pakan Ternak",
        },
      ],
      filter_tipe: [
        {
          value: "1",
          label: "Pemasukkan Benih Tanaman",
        },
        {
          value: "0",
          label: "Pengeluaran Benih Tanaman",
        },
      ],
      // widget
      count_all: "",
      detailTanaman: false,
      detailTanamanTitle: "",
      detailTanamanTitledb: "",
      countnotif_horti: "",
      countnotif_tp: "",
      countnotif_bun: "",
      countnotif_sdg: "",
      countnotif_hpt: "",
      countallstatus: "",
      status_pemasukan: "",
      status_pengeluaran: "",
      buttonLabel: "Export PDF",
      isDownloading: false,
      modalFilterPDF: false,
      start_pdf: "",
      end_pdf: "",
      status_pdf: "",
      perusahaan_pdf_selected: "",
      is_pemasukan_pdf: "",
      user_data: "",

      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        from: new Date(8640000000000000),
        customPredictor: (date) => {
          if (!this.start_pdf) {
            return false;
          }
          const start = new Date(this.start_pdf);
          const end = new Date(this.start_pdf);
          end.setDate(start.getDate() + 31);

          return date < start || date > end;
        },
      },
    };
  },
  methods: {
    parseTanamanNama(tanamanNamaString) {
      try {
        // Menggantikan karakter yang tidak diinginkan
        const cleanedString = tanamanNamaString.replace(/[\{\}\"\\]/g, "");
        // Memisahkan berdasarkan koma untuk mendapatkan array
        const tanamanNamaArray = cleanedString.split(",");
        return tanamanNamaArray;
      } catch (e) {
        console.error("Error parsing tanaman_nama:", e);
        // Mengembalikan array kosong jika terjadi error parsing
        return [];
      }
    },
    changeFilterTahun() {
      this.detailTanaman = false;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon tunggu sebentar...",
        showConfirmButton: false,
      });

      this.filterPermohonan();
      this.getCounting();
    },
    filterPermohonan() {
      this.loadingTable = true;
      let string_search = "";
      // alert(string_search);
      // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
      axios
        .get(this.apiLink + "api/permohonan/list-dashboard", {
          params: {
            is_pemasukan: this.is_pemasukan,
            start: this.start,
            end: this.end,
            status: this.status,
            // roles_id: this.session_data.roles_id,
            search: string_search,
            // pr_kategori: this.pr_kategori,
            nib: this.perusahaan_selected?.nib,
            search_permohonan: this.search_permohonan,
            tahun: this.tahun_filter,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_permohonan = response.data;
          var res_permohonan_data = res_permohonan.data[0];
          // console.log(res_permohonan_data);
          if (res_permohonan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_permohonan.data.message,
            });
          } else {
            this.loadingTable = false;
            this.list_permohonan = res_permohonan_data.data;
            this.paging = res_permohonan_data.links;
            this.curent_page = res_permohonan_data.current_page;
            this.excel_url = res_permohonan.data[1];
          }
        });
    },
    toPage(url) {
      this.loadingTable = true;
      // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_permohonan = response.data;
          var res_permohonan_data = res_permohonan.data[0];
          // console.log(res_permohonan_data);
          if (res_permohonan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_permohonan.data.message,
            });
          } else {
            this.loadingTable = false;
            this.list_permohonan = res_permohonan_data.data;
            this.paging = res_permohonan_data.links;
            this.curent_page = res_permohonan_data.current_page;
          }
        });
    },
    getBadge(status) {
      switch (status) {
        case "draft":
          return "bg-dark";
        case "Menunggu Perbaikan":
          return "warning";
        case "Ditolak":
          return "danger";
        case "Selesai":
          return "success";
        case "ajukan":
          return "info";
        case "menunggu perbaikan":
          return "warning";
        case "Dibatalkan":
          return "danger";
        default:
          return "info";
      }
    },
    getCounting() {
      axios
        .get(this.apiLink + "api/dashboard/tipepermohonan", {
          params: {
            tahun: this.tahun_filter,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_count = response.data;
          var res_count_data = res_count.data;
          console.log("dashboard count : ", res_count_data);
          if (res_count.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_count.data.message,
            });
          } else {
            // this.countnotif_horti = 0;
            var check_module = [];
            // var raw_count = ['Hortikultura', 'Tanaman Pangan', 'Perkebunan', 'Sumber Daya Genetik'];
            var horti = 0;
            var tp = 0;
            var bun = 0;
            var sdg = 0;
            var hpt = 0;
            this.countallstatus = res_count_data[0];

            $.each(res_count_data[0], function (index, val) {
              if (val.kategori == "Hortikultura") {
                horti = horti + val.jumlah;
              }
              if (val.kategori == "Tanaman Pangan") {
                tp = tp + val.jumlah;
              }
              if (val.kategori == "Perkebunan") {
                bun = bun + val.jumlah;
              }
              if (val.kategori == "Sumber Daya Genetik") {
                sdg = sdg + val.jumlah;
              }
              if (val.kategori == "Benih Tanaman Pakan Ternak") {
                hpt = hpt + val.jumlah;
              }
            });
            // console.log(horti);
            if (horti == 0) {
              this.countnotif_horti = "0";
            } else {
              this.countnotif_horti = horti;
            }

            if (tp == 0) {
              this.countnotif_tp = "0";
            } else {
              this.countnotif_tp = tp;
            }
            if (sdg == 0) {
              this.countnotif_sdg = "0";
            } else {
              this.countnotif_sdg = sdg;
            }
            if (bun == 0) {
              this.countnotif_bun = "0";
            } else {
              this.countnotif_bun = bun;
            }

            if (hpt == 0) {
              this.countnotif_hpt = "0";
            } else {
              this.countnotif_hpt = hpt;
            }
          }
          Swal.close();
        });
    },
    getListStatus(jenis_tanaman, in_db_name) {
      this.detailTanamanTitle = jenis_tanaman;
      this.detailTanamanTitledb = in_db_name;
      this.detailTanaman = true;
      var pemasukan = "";
      var pengeluaran = "";
      $.each(this.countallstatus, function (index, val) {
        if (val.tipe_permohonan == "Pemasukan " + in_db_name) {
          pemasukan = val.status_permohonan;
        }
        if (val.tipe_permohonan == "Pengeluaran " + in_db_name) {
          pengeluaran = val.status_permohonan;
        }
      });
      // console.log(pemasukan);
      console.log(pengeluaran);
      this.status_pemasukan = pemasukan;
      this.status_pengeluaran = pengeluaran;
    },
    getExcel() {
      var win = window.open(this.excel_url, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getPDFLink() {
      if (this.start_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      } else if (this.end_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      }

      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "";
      let module_name_pdf = "";
      const isPemasukan = parseInt(this.is_pemasukan_pdf, 10);
      if (this.pr_kategori === "") {
        module_name_pdf = "";
      } else {
        module_name_pdf = this.pr_kategori;
      }

      if (isPemasukan === 1) {
        fileName =
          "Export Permohonan Ijin Pemasukan Benih " + module_name_pdf + ".pdf";
      } else if (isPemasukan === 0) {
        fileName =
          "Export Permohonan Ijin Pengeluaran Benih " +
          module_name_pdf +
          ".pdf";
      } else {
        fileName =
          "Export Permohonan Ijin Pengeluaran/Pemasukan benih" +
          module_name_pdf +
          ".pdf";
      }

      axios
        .get(this.apiLink + "api/permohonan/pdf-permohonan", {
          params: {
            is_pemasukan: this.is_pemasukan_pdf,
            start: this.start_pdf,
            end: this.end_pdf,
            status: this.status_pdf,
            nib: this.perusahaan_selected_pdf?.nib,
            // pr_kategori: this.pr_kategori,
            tahun: this.tahun_filter,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
  beforeCreate() {
    if (this.reload_already == "no") {
      Vue.prototype.reload_already = "yes";
      localStorage.reload_already = "yes";
      window.location.reload();
    }
  },
  mounted() {
    console.log(this.access_token);
    $(".no-readonly").prop("readonly", false);
    // Highcharts.chart('permohonan-selesai', {
    //     chart: {
    //         type: 'column'
    //     },
    //     title: {
    //         text: ''
    //     },
    //     xAxis: {
    //         categories: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
    //         title: {
    //             text: null
    //         }
    //     },
    //     yAxis: {
    //         min: 0,
    //         title: {
    //             text: 'Jumlah Permohonan',
    //             align: 'high'
    //         },
    //         labels: {
    //             overflow: 'justify'
    //         }
    //     },
    //     tooltip: {
    //         valueSuffix: ' Permohonan'
    //     },
    //     plotOptions: {
    //         bar: {
    //             dataLabels: {
    //                 enabled: true
    //             }
    //         }
    //     },
    //     legend: {
    //         layout: 'vertical',
    //         align: 'right',
    //         verticalAlign: 'top',
    //         x: -40,
    //         y: 80,
    //         floating: true,
    //         borderWidth: 1,
    //         backgroundColor:
    //             Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    //         shadow: true
    //     },
    //     credits: {
    //         enabled: false
    //     },
    //     series: [{
    //         name: 'Benih Tanaman Pangan',
    //         data: [13, 12, 14, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     },
    //     {
    //         name: 'Benih Tanaman Perkebunan',
    //         data: [1, 14, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     },
    //     {
    //         name: 'Benih Tanaman Hortikultura',
    //         data: [31, 20, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     },
    //     {
    //         name: 'Sumber Daya Genetik Tanaman',
    //         data: [21, 25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }
    //     ]
    // });
    // $('#page-image').css('background-image', 'url("img/dashboard.png")');
  },
  created() {
    $(".no-readonly").prop("readonly", false);
    this.loadingTable = true;
    var i = 2021;
    var today = new Date();
    var year = today.getFullYear();
    do {
      this.list_tahun.push(i);
      i++;
    } while (i <= year);

    axios
      .get(this.apiLink + "api/permohonan/list-dashboard", {
        params: {
          is_pemasukan: this.is_pemasukan,
          start: this.start,
          end: this.end,
          status: this.status,
          // roles_id: this.session_data.roles_id
          tahun: this.tahun_filter,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan = response.data;
        var res_permohonan_data = res_permohonan.data[0];
        // console.log(res_permohonan_data);
        if (res_permohonan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan.data.message,
          });
        } else {
          this.loadingTable = false;
          this.list_permohonan = res_permohonan_data.data;
          this.paging = res_permohonan_data.links;
          this.curent_page = res_permohonan_data.current_page;
          this.excel_url = res_permohonan.data[1];
        }
      });
    axios
      .get(this.apiLink + "api/master/perusahaan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan = response.data;
        var res_perusahaan_data = res_perusahaan.data;
        if (res_perusahaan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan.data.message,
          });
        } else {
          this.list_perusahaan = res_perusahaan_data.master;
          // console.log(res_tanaman_data.master);
        }
      });
    axios
      .get(this.apiLink + "api/dashboard/tipepermohonan", {
        params: {
          tahun: this.tahun_filter,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_count = response.data;
        var res_count_data = res_count.data;
        if (res_count.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_count.data.message,
          });
        } else {
          // this.countnotif_horti = 0;
          var check_module = [];
          // var raw_count = ['Hortikultura', 'Tanaman Pangan', 'Perkebunan', 'Sumber Daya Genetik'];
          var horti = 0;
          var tp = 0;
          var bun = 0;
          var sdg = 0;
          var hpt = 0;
          this.countallstatus = res_count_data[0];
          $.each(res_count_data[0], function (index, val) {
            if (val.kategori == "Hortikultura") {
              horti = horti + val.jumlah;
            }
            if (val.kategori == "Tanaman Pangan") {
              tp = tp + val.jumlah;
            }
            if (val.kategori == "Perkebunan") {
              bun = bun + val.jumlah;
            }
            if (val.kategori == "Sumber Daya Genetik") {
              sdg = sdg + val.jumlah;
            }
            if (val.kategori == "Benih Tanaman Pakan Ternak") {
              hpt = hpt + val.jumlah;
            }
          });
          // console.log(horti);
          if (horti == 0) {
            this.countnotif_horti = "0";
          } else {
            this.countnotif_horti = horti;
          }

          if (tp == 0) {
            this.countnotif_tp = "0";
          } else {
            this.countnotif_tp = tp;
          }
          if (sdg == 0) {
            this.countnotif_sdg = "0";
          } else {
            this.countnotif_sdg = sdg;
          }
          if (bun == 0) {
            this.countnotif_bun = "0";
          } else {
            this.countnotif_bun = bun;
          }

          if (hpt == 0) {
            this.countnotif_hpt = "0";
          } else {
            this.countnotif_hpt = hpt;
          }
        }
      });

    axios
      .get(this.apiLink + "api/crud/user/get-roles-data", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var result = response.data;
        this.user_data = result;
      });
  },

  watch: {
    start_pdf(val) {
      if (val) {
        const start = new Date(val);
        const end = new Date(start);
        end.setDate(start.getDate() + 31);

        this.disabledDates = {
          ranges: [
            {
              from: new Date(-8640000000000000),
              to: new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate()
              ),
            },
            {
              from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
              to: new Date(8640000000000000),
            },
          ],
        };
      }
    },
  },
};
</script>